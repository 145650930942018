import React from 'react';

import Layout from '../shared/Layout/Layout';
import HeadMaker from '../HeadMaker';
import data from '../pages-fsd/JobReport2023Page/model/jobreport2023.data';
import BasicQuestion from '../widgets/BasicQuestion/BasicQuestion';
import JobResearchMain from '../widgets/JobResearchMain/JobResearchMain';
import GraduateCareer from '../widgets/GraduateCareer/GraduateCareer';
import Stereotypes from '../widgets/Stereotypes/Stereotypes';
import JobResearchStudentDescription from '../widgets/JobResearchStudentDescription/JobResearchStudentDescription';
import JobResearchBackGround from '../widgets/JobResearchBackground/JobResearchBackGround';
import GraduatesWork from '../widgets/GraduatesWork/GraduatesWork';
import GraduatesSalary from '../widgets/GraduatesSalary/GraduatesSalary';
import ResearchConclusions from '../widgets/ResearchConclusions/ResearchConclusions';
import JobResearchAuthor from '../widgets/JobResearchAuthor/JobResearchAuthor';

export function Head(props) {
  return (
    <HeadMaker
      {...props}
      seo={{ title: data.seo.title, meta: data.seo.meta }}
    />
  );
}

function JobReport2023Page() {
  return (
    <Layout>
      <JobResearchMain data={data.mainBlock} online />
      <BasicQuestion data={data.basicResearchQuestion} />
      <JobResearchStudentDescription data={data.studentDescription} />
      <JobResearchBackGround data={data.studentBackground} />
      <Stereotypes data={data.stereotypes} />
      <GraduateCareer data={data.graduateCareer} />
      <GraduatesWork data={data.graduatesWork} />
      <GraduatesSalary data={data.graduatesSalary} />
      <ResearchConclusions data={data.researchConclusions} />
      <JobResearchAuthor data={data.author} />
    </Layout>
  );
}

export default JobReport2023Page;
