import React from 'react';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Section from '../../shared/Section/Section';
import NumberIcon from '../../kit/icons/NumberIcon';
import { useJobReport2023Query } from '../../app/queries/jobReport2023.query';
import styles from './styles';
import Inset from '../../shared/Inset/Inset';

export default function BasicQuestion({ data }) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { jobReport2023Images } = useJobReport2023Query();

  return (
    <Section theme="light" anchor="mainquestion">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6.5}>
          <Box sx={styles.titleBlock}>
            <Box sx={styles.numberBlock}>
              <NumberIcon number={1} />
            </Box>
            {data.title && (
              <Typography variant="desktopH2" sx={styles.title}>
                {Array.isArray(data.title)
                  ? title.map((item) => (
                      <Typography key={item}>{item}</Typography>
                    ))
                  : data.title}
              </Typography>
            )}
          </Box>
          <Inset text={data.markedSubTitle} variant="desktopH4" />
          <Typography
            variant="desktopH4"
            color="kit.text.main"
            sx={{ mt: 5, fontSize: { xs: 16, lg: 22 } }}
          >
            Опрашиваемая аудитория:
          </Typography>

          <Typography
            variant="desktopP2"
            color="kit.text.main"
            sx={{ mb: 3, mt: 1 }}
          >
            {data.text2}
          </Typography>
          {data.bullets &&
            data.bullets.map((item) => (
              <Typography
                variant="desktopP2"
                key={item}
                color="kit.text.main"
                sx={{ mt: 1.5, display: 'flex', gap: 1 }}
                component="div"
              >
                <Typography color="kit.text.h">{`>`} </Typography> {item}
              </Typography>
            ))}
        </Grid>
        <Grid item xs={12} lg={5.5}>
          <Box sx={styles.image}>
            <GatsbyImage
              image={getImage(
                !isSm
                  ? jobReport2023Images['jobReport2023/question']
                  : jobReport2023Images['jobReport2023/question_mobile'],
              )}
              alt="reason_of_study"
              loading="lazy"
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
}
