export const styles = {
  container: {
    color: 'kit.text.main',
  },
  secondContainer: {
    paddingTop: '32px !important',
  },
  titleBlock: { display: { xs: 'block', lg: 'flex' } },
  numberBlock: { py: 1.5, mr: 2 },
  markedSubTitle: (theme) => ({
    paddingLeft: 3,
    margin: '0 40px 0 0',
    borderLeft: '5px solid #29EDFF',
    maxWidth: '595px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
      margin: '0 0 24px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      paddingLeft: 2,
      '& > *': {
        textTransform: 'none',
      },
      fontWeight: '400',
    },
  }),
  statement: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      fontWeight: '400',
      fontSize: '18px',
    },
  }),
  image: {
    boxShadow: '0px 16px 42px rgba(54, 43, 87, 0.1)',
    borderRadius: '20px',
    maxWidth: '595px',
    overflow: 'hidden',
  },
};
