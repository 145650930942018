export default {
  titleBlock: { display: { xs: 'block', lg: 'flex' } },
  numberBlock: { py: 1.5, mr: 2 },
  markedSubTitle: {
    textTransform: 'uppercase',
    paddingLeft: '40px',
    margin: `40px 40px 40px 0px`,
    borderLeft: '5px solid #29EDFF',
  },

  image: {
    boxShadow: '0px 16px 42px rgba(54, 43, 87, 0.1)',
    borderRadius: '20px',
    maxWidth: '536px',
    overflow: 'hidden',
  },
};
