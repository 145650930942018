export default {
  titleBlock: {
    color: 'kit.text.main',
    display: 'flex',
    flexDirection: 'column',
  },
  title: (theme) => ({
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('lg')]: {
      whiteSpace: 'normal',
    },
  }),
  titleFirstLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  titleFirstLineSM: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  numberBlock: (theme) => ({
    py: 1.3,
    mr: 2,
    [theme.breakpoints.down('xl')]: {
      paddingTop: 0.3,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 1.3,
    },
  }),
  markedSubTitle: {
    paddingLeft: '40px',
    margin: `40px 40px 40px 0px`,
    borderLeft: '5px solid #29EDFF',
  },
  image: {
    boxShadow: '0px 16px 42px rgba(54, 43, 87, 0.1)',
    borderRadius: '20px',
    overflow: 'hidden',
    maxWidth: '595px',
  },
};
