import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Section from '../../shared/Section/Section';
import { useJobReport2023Query } from '../../app/queries/jobReport2023.query';
import styles from './styles';
import Statistic from '../../shared/Statistic/Statistic';
import Inset from '../../shared/Inset/Inset';

export default function GraduatesSalary({ data }) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { jobReport2023Images } = useJobReport2023Query();

  return (
    <Section>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={7}>
          <Box sx={styles.titleBlock}>
            <Box sx={styles.numberBlock} />
            {data.title && (
              <Typography variant="desktopH3" color="kit.text.main">
                {Array.isArray(data.title)
                  ? title.map((item) => (
                      <Typography key={item}>{item}</Typography>
                    ))
                  : data.title}
              </Typography>
            )}
          </Box>
          <Inset text={data.markedSubTitle} customStyles={{ marginTop: 4 }} />
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Statistic
              justify={10}
              statistic={[data.statistic[0]]}
              variant="desktopH0"
            />
            <Statistic
              justify={7}
              statistic={[data.statistic[1]]}
              variant="desktopH0"
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={5} marginTop={2}>
          <Box sx={styles.image}>
            <GatsbyImage
              image={getImage(
                !isSm
                  ? jobReport2023Images['jobReport2023/graduates_salary']
                  : jobReport2023Images[
                      'jobReport2023/graduates_salary_mobile'
                    ],
              )}
              alt="most students age"
              loading="lazy"
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
}
