import React from 'react';
import { Box, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Section from '../../shared/Section/Section';
import styles from './styles';
import { useJobReport2023Query } from '../../app/queries/jobReport2023.query';
import SwiperComponent from '../../shared/SwiperComponent/SwiperComponent';

function JobResearchAuthor({ data }) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { jobReport2023Images } = useJobReport2023Query();

  return (
    <Section
      bg="job-research-author"
      customStyles={{ paddingBottom: { md: 6, sm: 1 } }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12} lg={8}>
          <Typography
            variant={!isSm ? 'desktopH2' : 'mobileH2'}
            color="kit.text.main"
          >
            {data.title}
          </Typography>
          <Paper sx={styles.cardContainer}>
            <Grid container mt={4} px={4} paddingTop={3} paddingBottom={4}>
              <Grid item xs={12} lg={5}>
                <Box sx={styles.avatar}>
                  <GatsbyImage
                    image={getImage(
                      jobReport2023Images['jobReport2023/angelina'],
                    )}
                    alt="most students age"
                    loading="lazy"
                  />
                </Box>
              </Grid>
              <Grid container item xs={12} lg={5}>
                <Grid item>
                  <Typography
                    variant={!isSm ? 'desktopH3' : 'mobileH3'}
                    sx={styles.name}
                  >
                    Ангелина
                    <br />
                    Черняева
                  </Typography>

                  <Typography
                    variant={!isSm ? 'desktopP3' : 'mobileP2'}
                    sx={styles.title}
                  >
                    эксперт-аналитик
                  </Typography>
                  <Typography
                    variant={!isSm ? 'desktopP3' : 'mobileP2'}
                    sx={styles.title}
                  >
                    в области рынка труда
                  </Typography>
                  <Typography
                    variant={!isSm ? 'desktopP2' : 'mobileP1'}
                    color="kit.text.main"
                    sx={styles.department}
                  >
                    Отдел маркетинговых исследований
                    <br />и аналитики hh.ru
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Box sx={styles.logo}>
                  <GatsbyImage
                    image={getImage(jobReport2023Images['jobReport2023/logo'])}
                    alt="most students age"
                    loading="lazy"
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Box mt={10} sx={styles.mainReportsContainer}>
        <Box>
          <Typography
            variant={!isSm ? 'desktopH2' : 'mobileH2'}
            color="kit.text.main"
          >
            {data.title1}
          </Typography>
        </Box>
        <SwiperComponent
          slidesPerView={1.4}
          spaceBetween={16}
          centeredSlides={false}
          breakpoints={{
            460: {
              slidesPerView: 2,
              spaceBetween: 4,
            },
            580: {
              slidesPerView: 2,
              spaceBetween: 8,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            1010: {
              slidesPerView: 3,
              spaceBetween: 14,
            },
            1410: {
              slidesPerView: 3.3,
              spaceBetween: 24,
            },
          }}
          slides={data.reportsList.map(
            (report) =>
              function () {
                return (
                  <Box py={3} sx={styles.reportsContainer}>
                    <Paper sx={{ boxShadow: 'none' }}>
                      <Box sx={styles.reportContainer}>
                        <Box>
                          <Typography
                            variant={!isSm ? 'desktopH3' : 'mobileH3'}
                            sx={styles.reportName}
                          >
                            Отчет {report.year} года
                          </Typography>

                          <Typography
                            variant={!isSm ? 'desktopP3' : 'mobileP2'}
                            mt={1}
                            mb={1}
                            sx={styles.description}
                          >
                            {report.descr}
                          </Typography>
                          <Link
                            href={report.href}
                            variant="kitPrimary"
                            target="_blank"
                          >
                            Читать
                          </Link>
                        </Box>
                      </Box>
                    </Paper>
                  </Box>
                );
              },
          )}
        />
      </Box>
    </Section>
  );
}

export default JobResearchAuthor;
