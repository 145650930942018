import { useStaticQuery, graphql } from 'gatsby';
import { getImageDataMapByFileEdges } from '../../helpers/lib';

export const useJobReport2023Query = () => {
  const { allFile } = useStaticQuery(graphql`
    query JobReport2023Query {
      allFile(filter: { relativeDirectory: { regex: "/jobReport2023/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            relativeDirectory
            name
            absolutePath
          }
        }
      }
    }
  `);

  const jobReport2023Images = getImageDataMapByFileEdges(allFile.edges);
  return { jobReport2023Images };
};
