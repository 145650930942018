const styles = {
  cardContainer: (theme) => ({
    boxShadow: '0px 36px 56px rgba(174, 168, 207, 0.18)',
    borderRadius: {
      xs: '16px',
      lg: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      pb: 3,
      px: 3,
    },
  }),
  container: {
    boxShadow: '0px 36px 56px rgba(174, 168, 207, 0.18)',
    borderRadius: '16px',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: {
      xs: '22px',
      lg: '38px',
    },
  },
  info: {
    paddingLeft: {
      xs: '21px',
      lg: '28px',
    },
  },
  name: (theme) => ({
    marginBottom: '12px',
    color: 'kit.text.main',
    [theme.breakpoints.down('sm')]: {
      mt: 3,
    },
  }),
  title: {
    color: 'kit.text.black',
    opacity: '0.6',
  },
  department: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      my: 3,
    },
  }),
  avatar: (theme) => ({
    width: '302px',
    height: {
      xs: '265px',
    },
    overflow: 'hidden',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    [theme.breakpoints.down('xl')]: {
      maxWidth: '260px',
      height: 'auto',
    },
    [theme.breakpoints.down('lg')]: {
      width: 'auto',
      maxHeight: '302px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'none',
    },
  }),
  logo: (theme) => ({
    width: '80px',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      width: '52px',
      float: 'none',
    },
  }),
  mainReportsContainer: (theme) => ({
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
    },
  }),
  reportsContainer: {
    marginTop: 0,
    maxWidth: '360px',
  },
  reportContainer: (theme) => ({
    boxShadow: '0px 12px 8px rgba(174, 168, 207, 0.18)',
    p: 3,
    borderRadius: '16px',
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0px 16px 12px rgba(174, 168, 207, 0.18)',
    },
  }),
  reportName: (theme) => ({
    marginBottom: 1.5,
    color: 'kit.text.main',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 2,
    },
  }),
  description: {
    color: 'kit.text.secondary',
    minHeight: '38px',
  },
};

export default styles;
