export default {
  titleBlock: { display: { xs: 'block', lg: 'flex' } },
  title: {
    color: 'kit.text.main',
    whiteSpace: 'pre-wrap',
    mt: {
      xs: 3,
      lg: 0,
    },
  },
  numberBlock: (theme) => ({
    paddingTop: 1.3,
    marginRight: 2,
    [theme.breakpoints.down('xl')]: {
      paddingTop: 0.4,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 1.3,
    },
  }),
  markedSubTitle: {
    paddingLeft: '40px',
    margin: `40px 40px 40px 0px`,
    borderLeft: '5px solid #29EDFF',
  },
  image: (theme) => ({
    boxShadow: '0px 16px 42px rgba(54, 43, 87, 0.1)',
    borderRadius: '20px',
    overflow: 'hidden',
    maxWidth: '595px',
    [theme.breakpoints.down('md')]: {
      marginTop: 2,
    },
  }),
};
