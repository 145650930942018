export default {
  arrowIcon: {
    color: '#4520AB',
    fontSize: '50px',
  },
  markedSubTitle: {
    paddingLeft: '40px',
    margin: `40px 40px 40px 0px`,
    borderLeft: '5px solid #29EDFF',
  },
  image: {
    boxShadow: '0px 16px 42px rgba(54, 43, 87, 0.1)',
    borderRadius: '20px',
    overflow: 'hidden',
    maxWidth: '595px',
  },
};
