import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useJobReport2023Query } from '../../app/queries/jobReport2023.query';
import { styles } from './styles';
import Section from '../../shared/Section/Section';

export default function Stereotypes({ data }) {
  const { jobReport2023Images } = useJobReport2023Query();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Section theme="light">
      <Grid container spacing={4} sx={styles.container}>
        <Grid item xs={12}>
          <Box sx={styles.titleBlock}>
            {data.title && (
              <Typography variant={!isSm ? 'desktopH3' : 'mobileH3'}>
                {data.title}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} lg={6.5} mb={0}>
            <Box sx={styles.markedSubTitle}>
              <Typography
                variant={!isSm ? 'desktopH4' : 'mobileH4'}
                color="kit.text.main"
                sx={styles.statement}
              >
                {data.statement}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5.6}>
          <Box sx={styles.image}>
            <GatsbyImage
              image={getImage(
                jobReport2023Images[
                  !isSm
                    ? 'jobReport2023/most_students_age'
                    : 'jobReport2023/most_students_age_mobile'
                ],
              )}
              alt="most students age"
              loading="lazy"
            />
          </Box>
        </Grid>
        {!isSm && <Grid item xs={0} md={0.8} />}
        <Grid item xs={12} lg={5.6}>
          <Box sx={styles.image}>
            <GatsbyImage
              image={getImage(
                jobReport2023Images[
                  !isSm
                    ? 'jobReport2023/women_percentage'
                    : 'jobReport2023/women_percentage_mobile'
                ],
              )}
              alt="women percentage"
              loading="lazy"
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
}
