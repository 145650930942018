import React from 'react';
import { SvgIcon } from '@mui/material';

export function HH(props) {
  return (
    <SvgIcon {...props} viewBox="25 25 337 338" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.285 123.198C125.351 138.887 106.507 183.167 122.196 222.101C137.885 261.035 182.165 279.879 221.099 264.191C260.033 248.502 278.877 204.222 263.188 165.288C247.499 126.353 203.219 107.509 164.285 123.198ZM111.972 226.221C94.008 181.641 115.585 130.938 160.165 112.974C204.746 95.0104 255.448 116.587 273.412 161.168C291.376 205.748 269.799 256.451 225.219 274.414C180.638 292.378 129.936 270.802 111.972 226.221Z"
        fill="#F4F2FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.3764 169.563C79.0486 224.965 113.157 280.682 168.559 294.01C223.961 307.337 279.678 273.229 293.006 217.827C306.333 162.425 272.225 106.708 216.823 93.3806C161.421 80.0527 105.704 114.161 92.3764 169.563ZM165.981 304.726C104.66 289.975 66.9078 228.306 81.6595 166.985C96.4112 105.664 158.08 67.912 219.401 82.6637C280.722 97.4154 318.474 159.084 303.723 220.405C288.971 281.726 227.302 319.478 165.981 304.726Z"
        fill="#F4F2FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.8332 163.748C49.2934 233.811 92.6831 304.017 162.747 320.557C232.81 337.097 303.016 293.707 319.556 223.644C336.096 153.58 292.706 83.3741 222.643 66.8343C152.579 50.2945 82.3729 93.6841 65.8332 163.748ZM160.214 331.285C84.2258 313.346 37.167 237.203 55.1054 161.215C73.0438 85.2268 149.187 38.1681 225.175 56.1066C301.163 74.045 348.222 150.188 330.284 226.176C312.345 302.164 236.203 349.223 160.214 331.285Z"
        fill="#F4F2FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2686 168.094C23.1295 253.933 81.2541 334.981 167.094 349.121C252.933 363.26 333.982 305.135 348.121 219.296C362.26 133.457 304.135 52.408 218.296 38.2689C132.456 24.1298 51.4077 82.2543 37.2686 168.094ZM165.302 359.997C73.456 344.868 11.2641 258.148 26.3925 166.302C41.521 74.4561 128.241 12.2643 220.087 27.3928C311.933 42.5213 374.125 129.241 358.997 221.087C343.868 312.933 257.148 375.125 165.302 359.997Z"
        fill="#F4F2FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.186 57.835C187.186 54.7912 189.654 52.3237 192.698 52.3237C270.775 52.3237 334.069 115.618 334.069 193.695C334.069 271.772 270.775 335.066 192.698 335.066C144.753 335.066 102.384 311.193 76.8298 274.711C75.0835 272.218 75.6888 268.782 78.1819 267.035C80.6749 265.289 84.1116 265.894 85.8579 268.387C109.438 302.051 148.5 324.043 192.698 324.043C264.687 324.043 323.046 265.684 323.046 193.695C323.046 121.705 264.687 63.3464 192.698 63.3464C189.654 63.3464 187.186 60.8789 187.186 57.835Z"
        fill="#CECECE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.188 112.179C187.188 109.135 189.655 106.667 192.699 106.667C240.763 106.667 279.726 145.631 279.726 193.695C279.726 196.739 277.258 199.206 274.215 199.206C271.171 199.206 268.703 196.739 268.703 193.695C268.703 151.719 234.675 117.69 192.699 117.69C189.655 117.69 187.188 115.223 187.188 112.179Z"
        fill="#CECECE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.188 85.0069C187.188 81.9631 189.655 79.4956 192.699 79.4956C255.769 79.4956 306.898 130.624 306.898 193.695C306.898 256.765 255.769 307.894 192.699 307.894C189.655 307.894 187.188 305.426 187.188 302.382C187.188 299.339 189.655 296.871 192.699 296.871C249.682 296.871 295.875 250.677 295.875 193.695C295.875 136.712 249.682 90.5182 192.699 90.5182C189.655 90.5182 187.188 88.0507 187.188 85.0069Z"
        fill="#CECECE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.184 30.6632C187.184 27.6194 189.652 25.1519 192.695 25.1519C285.779 25.1519 361.238 100.611 361.238 193.695C361.238 286.778 285.779 362.238 192.695 362.238C99.6118 362.238 24.1523 286.778 24.1523 193.695C24.1523 190.651 26.6198 188.184 29.6637 188.184C32.7075 188.184 35.175 190.651 35.175 193.695C35.175 280.691 105.699 351.215 192.695 351.215C279.691 351.215 350.216 280.691 350.216 193.695C350.216 106.699 279.691 36.1745 192.695 36.1745C189.652 36.1745 187.184 33.707 187.184 30.6632Z"
        fill="#CECECE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M192.887 154.195C170.807 154.195 152.887 172.115 152.887 194.195C152.887 216.275 170.807 234.195 192.887 234.195C214.967 234.195 232.887 216.275 232.887 194.195C232.887 172.115 214.967 154.195 192.887 154.195ZM191.327 211.395H184.887V198.275C184.887 195.675 184.767 194.035 184.527 193.315C184.287 192.595 183.847 192.075 183.247 191.635C182.647 191.235 181.847 190.995 180.927 190.995C179.847 190.995 178.887 191.275 178.087 191.795C177.247 192.315 176.607 193.115 176.247 194.195C175.847 195.275 175.647 196.835 175.647 198.915V211.355H169.207V176.115H175.647V189.715C177.727 187.235 180.207 185.995 183.087 185.995C184.567 185.995 185.887 186.275 187.087 186.835C188.287 187.395 189.167 188.115 189.767 188.995C190.367 189.875 190.767 190.835 191.007 191.915C191.247 192.955 191.327 194.595 191.327 196.835V211.395ZM216.647 211.395H210.207V198.275C210.207 195.675 210.087 194.035 209.847 193.315C209.607 192.595 209.167 192.075 208.567 191.635C207.967 191.235 207.167 190.995 206.247 190.995C205.167 190.995 204.207 191.275 203.407 191.795C202.567 192.315 201.967 193.115 201.567 194.195C201.167 195.275 200.967 196.835 200.967 198.915V211.355H194.527V176.115H200.967V189.715C203.047 187.235 205.527 185.995 208.407 185.995C209.887 185.995 211.207 186.275 212.407 186.835C213.607 187.395 214.487 188.115 215.087 188.995C215.687 189.875 216.087 190.835 216.327 191.915C216.567 192.955 216.647 194.595 216.647 196.835V211.395Z"
        fill="#FD000F"
      />
    </SvgIcon>
  );
}
