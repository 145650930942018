import React from 'react';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Section from '../../shared/Section/Section';
import { useJobReport2023Query } from '../../app/queries/jobReport2023.query';
import styles from './styles';
import Inset from '../../shared/Inset/Inset';
import { SberLogo } from '../../kit/icons/SberLogo';
import { KasperskyLogo } from '../../kit/icons/KasperskyLogo';
import { OzonLogo } from '../../kit/icons/OzonLogo';
import { YandexLogo } from '../../kit/icons/YandexLogo';
import { AlfaLogo } from '../../kit/icons/AlfaLogo';
import { GazpromLogo } from '../../kit/icons/GazpromLogo';
import AlumniCompanies from '../AlumniCompanies/AlumniCompanies';

const logos = [
  <SberLogo />,
  <KasperskyLogo />,
  <OzonLogo />,
  <YandexLogo />,
  <AlfaLogo />,
  <GazpromLogo />,
];
export default function GraduatesWork({ data }) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isBelowXl = useMediaQuery((theme) => theme.breakpoints.down('xl'));

  const { jobReport2023Images } = useJobReport2023Query();

  return (
    <Section>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6.5}>
          <Box sx={styles.titleBlock}>
            {data.title && (
              <Typography variant="desktopH3" color="kit.text.main">
                {Array.isArray(data.title)
                  ? title.map((item) => (
                      <Typography key={item}>{item}</Typography>
                    ))
                  : data.title}
              </Typography>
            )}
          </Box>
          <Inset text={data.markedSubTitle} customStyles={{ my: 4 }} />
          <AlumniCompanies
            bottomText={
              <Typography
                textAlign={{ xs: 'center', lg: 'right' }}
                variant="desktopP1"
                color="kit.text.secondary"
                sx={{
                  fontSize: { xs: 14, lg: 16 },
                  mt: { xs: 3, lg: 4 },
                  mb: { xs: 2 },
                  mr: { lg: 4, xs: 0 },
                }}
              >
                и еще 200+ компаний
              </Typography>
            }
            color="kit.text.secondary"
            order={[0, 1, 2, 5, ...(isBelowXl ? [3] : []), 3, 4]}
            rowCount={3}
          />
        </Grid>
        <Grid item xs={12} lg={5.5}>
          <Box sx={styles.image}>
            <GatsbyImage
              image={getImage(
                !isSm
                  ? jobReport2023Images['jobReport2023/graduates_work']
                  : jobReport2023Images['jobReport2023/graduates_work_mobile'],
              )}
              alt="most students age"
              loading="lazy"
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
}
