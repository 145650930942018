const styles = {
  container: {
    position: 'relative',
  },
  mainContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '10px',
    color: 'kit.text.main',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      gap: 0,
    },
  }),
  year: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: '0',
    },
  }),
  left: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    width: {
      lg: 8 / 12,
      xs: 1,
    },
    '> div, > h1, > p': {
      marginBottom: '6px',
    },
  },
  right: {
    width: {
      lg: 4 / 12,
      xs: 1,
    },
  },
  linksContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '339px',
    paddingTop: '70px',
    [theme.breakpoints.down('lg')]: {
      paddingTop: '32px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  }),
  links: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    '& > div > a': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
    },
    [theme.breakpoints.down('lg')]: {
      gap: '24px',
    },
  }),
  link: {
    textDecoration: 'none',
    color: 'kit.link.main',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  detailsContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '75%',
    [theme.breakpoints.down('xl')]: {
      width: '75%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& > p': {
      padding: '0px 4px',
      backgroundColor: theme.palette.kit.accent2,
    },
  }),
  imageAndLinks: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'flex-start',
    },
  }),
  hhimage: (theme) => ({
    width: '80%',
    height: 'auto',
    marginTop: '65px',
    maxWidth: '337px',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  }),
  numberBlock: {
    py: 1.5,
    mr: 2,
  },
  contentsContainer: {
    color: 'kit.text.main',
  },
  numberIconAndText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '24px',
  },
  contents: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
  },
};

export default styles;
