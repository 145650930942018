import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useJobReport2023Query } from '../../app/queries/jobReport2023.query';
import Section from '../../shared/Section/Section';
import styles from './styles';
import NumberIcon from '../../kit/icons/NumberIcon';
import Statistic from '../../shared/Statistic/Statistic';
import Inset from '../../shared/Inset/Inset';

function JobResearchStudentDescription({ data }) {
  const { jobReport2023Images } = useJobReport2023Query();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Section anchor="whoandwhy">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6.5}>
          <Box sx={styles.titleBlock}>
            <Box sx={isSm ? styles.titleFirstLineSM : styles.titleFirstLine}>
              <Box sx={styles.numberBlock}>
                <NumberIcon number={2} />
              </Box>
              <Typography
                variant={!isSm ? 'desktopH2' : 'mobileH2'}
                sx={styles.title}
              >
                {data.title}
              </Typography>
            </Box>
          </Box>
          <Box pr={1}>
            <Inset text={data.markedSubTitle} />
          </Box>
          <Grid item xs={12} md={4}>
            <Statistic
              justify={7}
              statistic={data.statistic}
              variant="desktopH0"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={5.5} sx={styles.imageContainer}>
          <Box sx={styles.image}>
            <GatsbyImage
              image={getImage(
                jobReport2023Images[
                  !isSm
                    ? 'jobReport2023/reason_of_study'
                    : 'jobReport2023/reason_of_study_mobile'
                ],
              )}
              alt="reason_of_study"
              loading="lazy"
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
}

export default JobResearchStudentDescription;
