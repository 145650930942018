import * as React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'textColor',
})(({ theme, bgColor, textColor }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '12px',
  height: '36px',
  width: '36px',
  minWidth: '36px',
  backgroundColor: theme.getColor(bgColor),
  color: theme.getColor(textColor),
  // backgroundColor: theme.palette[bgColor].main,
  // color: theme.palette[textColor].main,
}));

function NumberIcon({
  number = 1,
  bgColor = 'kit.background.purple',
  textColor = 'kit.text.mainInverse',
}) {
  return (
    <StyledBox bgColor={bgColor} textColor={textColor}>
      <Typography variant="desktopP1">{number}</Typography>
    </StyledBox>
  );
}

export default NumberIcon;
