const data = {
  seo: {
    title:
      'Отчет центра исследований и аналитики HeadHunter о трудоустройстве и выпускниках Эльбрус Буткемп',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Хедхантер провел опрос выпускников Школы программирования «Эльбрус Буткемп» в 2023 году, благодаря чему мы можем наблюдать отчет об эффективности работы буткемпа. Здесь рассматриваются главные вопросы исследования, кто и зачем идёт в буткемп, а также вы узнаете о карьере и трудоустройстве наших выпускников.',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'Отчет центра исследований и аналитики HeadHunter о трудоустройстве и выпускниках Эльбрус Буткемп',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Хедхантер провел опрос выпускников Школы программирования «Эльбрус Буткемп» в 2023 году, благодаря чему мы можем наблюдать отчет об эффективности работы буткемпа. Здесь рассматриваются главные вопросы исследования, кто и зачем идёт в буткемп, а также вы узнаете о карьере и трудоустройстве наших выпускников.',
      },
      {
        property: 'og:image',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/04.JPG',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
    ],
  },
  mainBlock: {
    title: 'Исследование hh.ru: трудоустройство выпускников Эльбрус Буткемп',
    subtitle: 'отчёт по исследованию',
    description:
      'Эльбрус Буткемп — первая в России школа программирования в формате буткемп. Программа предполагает интенсивный формат обучения c фокусом на практике и дальнейшем трудоустройстве студентов. Обучение проходит в онлайне, а также в кампусах в Москвы и Санкт-Петербурга.',
    details:
      'Центр исследований и аналитики hh.ru провел опрос выпускников Школы программирования «Эльбрус буткемп» в апреле 2023',
    afterText: null,
    links: [
      {
        text: 'Читать полный отчет',
        href: '/docs/hh_elbrus_report.pdf',
      },
    ],
    contents: [
      { text: 'Оcновной вопрос исследования →', anchor: 'mainquestion' },

      {
        text: 'Кто и зачем идет в буткемп по программированию? →',
        anchor: 'whoandwhy',
      },
      {
        text: 'Трудоустройство и карьера выпускников →',
        anchor: 'career',
      },
      { text: 'Выводы исследования →', anchor: 'conclusions' },
    ],
  },
  basicResearchQuestion: {
    title: 'Основной вопрос\nисследования',
    markedSubTitle:
      'К каким результатам в трудоустройстве приходят выпускники буткемпа спустя 3 месяца обучения?',
    bullets: [
      'закончили анкету и вошли в итоговую выборку 408;',
      'направление — «Веб-разработка JavaScript»;',
      'кампусы: Москва, Санкт-Петербург и онлайн.',
    ],
    text2:
      'К опросу hh.ru было привлечено 718 выпускников, которые окончили буткемп с октября 2021 по ноябрь 2022 гг',
    img: '/images/hse/developers.png',
    stats: {
      number: 510,
      info: 'выпускников Эльбрус Буткемп было привлечено к исследованию',
    },
  },
  studentDescription: {
    title: 'Кто и зачем идет в буткемп\nпо программированию?',
    markedSubTitle:
      'Ключевая цель поступающих — сменить работу и профессиональную деятельность. Не менее важным для эльбрусовцев было увеличить заработную плату и расширить карьерные возможности',
    statistic: [
      {
        id: '1',
        value: '90%',
        description: 'опрошенных, хотели\nсменить работу',
      },
    ],
  },
  studentBackground: {
    title: 'Нужен ли опыт в программировании?',
    markedSubTitle:
      'hh.ru отмечают в отчёте, что 94% опрошенных выпускников не имели профессионального опыта в программировании и только 4% ранее уже занимались разработкой.',
    text: 'В буткемп приходят люди с самым разным профессиональным опытом и образованием.',
    text1: `Инженеры, бухгалтеры, менеджеры, официанты, адвокаты, люди
    творческих профессий и медики — все они успешно окончили буткемп и
    нашли себя в разработке.`,
    comment: ` * Полный перечень профессий и сфер, где работали выпускники до
    поступления в Эльбрус Буткемп вы можете найти в полной версии отчета`,
  },
  graduateCareer: {
    title: 'Трудоустройство и карьера выпускников',
    text1:
      '86% выпускников нашли новое место работы по профессии разработчик в первые 3 месяца после окончания буткемпа.',
    text2:
      'Программа обучения в буткемпе включает карьерную неделю и дальнейшее консультирование по трудоустройству. После выпуска студенты получают доступ в закрытое сообщество 1500+ программистов-выпускников, работающих в 150+ компаниях.',
    img: '/images/hse/developers.png',
    statistic: [
      {
        id: '1',
        value: '88%',
        description: 'студентов успешно заканчивают буткемп',
      },
      {
        id: '2',
        value: '90%',
        description: 'выпускников Эльбрус Буткемп находят работу',
      },
    ],
  },
  stereotypes: {
    title: 'Кто подходит для работы в сфере IT?',
    statement:
      'В IT нет ограничений по возрасту или полу. При желании, научиться программировать может каждый.',
  },
  graduatesWork: {
    title: 'Где работают выпускники Эльбрус?',
    markedSubTitle:
      'Основные работодатели — компании в сфере IT и интернета, цифровые бизнес системы (Яндекс, VK, Сбер, Озон), а также организации, оказывающие финансовые и b2b услуги.',

    statistic: [],
  },
  graduatesWorkFormat: {
    title: 'Какой формат работы преобладает: онлайн или оффлайн?',
    text1:
      'Большинство выпускников (86%) работает онлайн или в гибридном формате — к чему и стремились две трети опрошенных в начале своего пути в IT, поэтому можно сделать вывод, что программирование в большинстве случаев даёт возможность работать удалённо из любой точки мира.',
    statistic: [
      {
        id: '1',
        value: '63%',
        description: 'выпускников работает удалённо (онлайн)',
      },
    ],
  },
  graduatesWorkAreas: {
    title: 'Сферы работы',
    markedSubTitle:
      'Основные работодатели — компании в сфере IT и интернета, цифровые бизнес системы (Яндекс, VK, Сбер, Озон), а также организации, оказывающие финансовые и b2b услуги. Большинство выпускников (86%) работает удаленно или в гибридном формате — к чему и стремились две трети опрошенных в начале своего пути в IT.',
  },
  graduatesSalary: {
    title: 'Как меняется зарплата выпускников буткемпа?',
    markedSubTitle:
      'Аналитики hh.ru подсчитали, что средний ежемесячный заработок выпускника до обучения составлял 93 000 рублей. Сразу после обучения зарплата выросла до 124 000 рублей, на текущей работе в среднем — 141 000 рублей.',

    statistic: [
      {
        id: '1',
        value: '141 000',
        description: 'рублей — средний ежемесячный заработок выпускника',
      },
      {
        id: '2',
        value: '+34%',
        description: 'прирост от зарплаты\nдо начала обучения',
      },
    ],
  },
  researchConclusions: {
    title: 'Выводы исследования',
    points: [
      {
        header: 'Успешное трудоустройство: ',
        text: '86% выпускников находят работу в первые 3 месяца после окончания буткемпа;',
      },
      {
        header: 'Достижение поставленной цели: ',
        text: '9 из 10 выпускников находят себя в IT-сфере;',
      },
      {
        header: 'Высокая зарплата: ',
        text: 'в среднем выпускники Эльбрус Буткемп зарабатывают 141 000 рублей;',
      },
      {
        header: 'Поиск работы: ',
        text: 'уменьшился процент выпускников, нашедших работу за 1-2 месяца после выпуска — доля сместилась в пользу более долгого срока.',
      },
    ],
  },
  author: {
    title: 'Автор',
    title1: 'Все исследования',
    reportsList: [
      {
        year: '2023',
        descr: 'Исследование hh.ru',
        href: '/docs/hh_elbrus_report.pdf',
      },
      {
        year: '2021',
        descr: 'Исследование Высшей школы экономики',
        href: 'https://ioe.hse.ru/mirror/pubs/share/533083120.pdf',
      },
    ],
  },
};

export default data;
