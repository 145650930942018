import { Box, Typography } from '@mui/material';
import React from 'react';
import styles from './styles';

export default function Inset({
  text,
  variant = 'desktopH4',
  uppercase,
  customStyles,
}) {
  return (
    <Box sx={styles.inset}>
      <Typography
        variant={variant}
        color="kit.text.main"
        textTransform={uppercase ? 'uppercase' : 'none'}
        sx={{
          fontSize: { xs: 18, lg: 22 },
          ...customStyles,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
