import React from 'react';
import { Box, Chip, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';
import Section from '../../shared/Section/Section';
import styles from './styles';
import { HH } from './images/HH';
import NumberIcon from '../../kit/icons/NumberIcon';

function JobResearchMain({ data }) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Box>
      <Section bg="percentage" sx={styles.container}>
        <Box sx={styles.mainContainer}>
          <Box sx={styles.left}>
            <Chip
              color="secondary"
              size="small"
              variant="status"
              label="2023"
              sx={styles.year}
            />
            <Box>
              <Typography
                variant={!isSm ? 'desktopP1' : 'mobileP2'}
                sx={styles.subtitle}
              >
                {data.subtitle}
              </Typography>
            </Box>
            <Typography
              variant={!isSm ? 'desktopH1' : 'mobileH2'}
              component="h1"
            >
              {data.title}
            </Typography>
            <Typography variant={!isSm ? 'desktopP2' : 'mobileP2'}>
              {data.description}
            </Typography>
            <Box sx={styles.detailsContainer}>
              <Typography variant={!isSm ? 'desktopP2' : 'mobileP2'}>
                Детали исследования
              </Typography>
              <Typography variant={!isSm ? 'desktopP2' : 'mobileP2'}>
                {data.details}
              </Typography>
            </Box>
            <Typography variant={!isSm ? 'desktopP2' : 'mobileP2'}>
              {data.afterText}
            </Typography>
          </Box>
          <Box sx={styles.right}>
            <Box component="span" sx={styles.imageAndLinks}>
              <HH sx={styles.hhimage} />
              <Box component="span" sx={styles.linksContainer}>
                <Box sx={styles.links}>
                  {data.links &&
                    data.links.map((link, index) => (
                      <Box key={link}>
                        <Link key={index} href={link.href} variant="kitPrimary">
                          {link.text}
                        </Link>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section>
        <Box sx={styles.contentsContainer}>
          <Typography variant={!isSm ? 'desktopH2' : 'mobileH2'}>
            Содержание
          </Typography>
          <Box sx={styles.contents}>
            {data.contents &&
              data.contents.map((item, index) => (
                <Box key={index} sx={styles.numberIconAndText}>
                  <NumberIcon number={index + 1} />
                  <Typography variant={!isSm ? 'desktopH3' : 'mobileH3'}>
                    <Link to={`#${item.anchor}`} sx={styles.link}>
                      {item.text}
                    </Link>
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>
      </Section>
    </Box>
  );
}

export default JobResearchMain;
