import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useJobReport2023Query } from '../../app/queries/jobReport2023.query';
import Section from '../../shared/Section/Section';
import NumberIcon from '../../kit/icons/NumberIcon';
import Statistic from '../../shared/Statistic/Statistic';
import Inset from '../../shared/Inset/Inset';
import styles from './styles';

export default function GraduateCareer({ data }) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { jobReport2023Images } = useJobReport2023Query();

  return (
    <Section anchor="career">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6.5}>
          <Box sx={styles.titleBlock}>
            <Box sx={styles.numberBlock}>
              <NumberIcon number={3} />
            </Box>
            {data.title && (
              <Typography variant="desktopH2" color="kit.text.main">
                {Array.isArray(data.title)
                  ? title.map((item) => (
                      <Typography key={item}>{item}</Typography>
                    ))
                  : data.title}
              </Typography>
            )}
          </Box>
          <Typography
            variant={!isSm ? 'desktopP1' : 'mobileP2'}
            color="kit.text.main"
            sx={{ mt: 4 }}
          >
            {data.text1}
          </Typography>
          <Typography
            variant={!isSm ? 'desktopP1' : 'mobileP2'}
            color="kit.text.main"
            sx={{ mt: 3 }}
          >
            {data.text2}
          </Typography>
          <Inset text={data.markedSubTitle} />
          <Statistic
            justify={7}
            statistic={data.statistic}
            variant="desktopH0"
          />
        </Grid>
        <Grid item xs={12} lg={5.5}>
          <Box sx={styles.image}>
            <GatsbyImage
              image={getImage(
                !isSm
                  ? jobReport2023Images['jobReport2023/graduates_career']
                  : jobReport2023Images[
                      'jobReport2023/graduates_career_mobile'
                    ],
              )}
              alt="most students age"
              loading="lazy"
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
}
