import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useJobReport2023Query } from '../../app/queries/jobReport2023.query';
import Section from '../../shared/Section/Section';
import styles from './styles';
import Inset from '../../shared/Inset/Inset';

function JobResearchBackGround({ data }) {
  const { jobReport2023Images } = useJobReport2023Query();
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <Section>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6.5}>
          <Typography
            color="kit.text.main"
            variant={!isSm ? 'desktopH3' : 'mobileH3'}
          >
            {data.title}
          </Typography>
          <Inset text={data.markedSubTitle} />
          <Typography
            variant={!isSm ? 'desktopP1' : 'mobileP2'}
            mt={5}
            mb={2}
            color="kit.text.main"
          >
            {data.text}
          </Typography>
          <Typography
            variant={!isSm ? 'desktopP1' : 'mobileP2'}
            sx={{ mb: { xs: 0, md: 18 } }}
            color="kit.text.main"
          >
            {data.text1}
          </Typography>
          {!isLgDown && (
            <Typography variant={!isSm ? 'desktopP2' : 'mobileP2'}>
              {data.comment}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} lg={5.5}>
          <Box sx={styles.image}>
            <GatsbyImage
              image={getImage(
                jobReport2023Images[
                  !isSm
                    ? 'jobReport2023/job_background'
                    : 'jobReport2023/job_background_mobile'
                ],
              )}
              alt="job_background"
              loading="lazy"
            />
          </Box>
        </Grid>
        {isLgDown && (
          <Grid item xs={12}>
            <Typography variant="mobileP2" pt={1}>
              {data.comment}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Section>
  );
}

export default JobResearchBackGround;
