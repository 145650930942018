import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { Button, Link } from 'gatsby-theme-material-ui';
import Section from '../../shared/Section/Section';
import styles from './styles';
import NumberIcon from '../../kit/icons/NumberIcon';

export default function ResearchConclusions({ data }) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Section theme="dark" anchor="conclusions">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={styles.titleBlock}>
            <Box sx={styles.numberBlock}>
              <NumberIcon
                number={4}
                bgColor="kit.background.turquoise"
                textColor="kit.text.h"
              />
            </Box>{' '}
            {data.title && (
              <Typography
                variant={!isSm ? 'desktopH2' : 'mobileH2'}
                color="kit.text.mainInverse"
                sx={styles.title}
              >
                {data.title}
              </Typography>
            )}
          </Box>
        </Grid>
        {data.points.map((point, index) => (
          <Grid
            item
            xs={12}
            md={6}
            key={point.header}
            sx={{ mt: 3, display: 'flex' }}
          >
            <Box sx={{ display: 'flex', maxWidth: 595 }}>
              <Box sx={{ pr: '14px' }}>
                <Typography
                  variant={!isSm ? 'desktopH3' : 'mobileH3'}
                  component="span"
                  sx={{ lineHeight: '90% !important' }}
                >
                  {'> '}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant={!isSm ? 'desktopH4' : 'mobileH4'}
                  component="span"
                  fontWeight={700}
                  sx={{ fontSize: { xs: 22, lg: 24 } }}
                >
                  {point.header}
                </Typography>
                <Typography
                  variant="desktopH4"
                  component="span"
                  sx={{ fontSize: { xs: 18, lg: 22 } }}
                >
                  {point.text}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}{' '}
        <Grid item xs={12} display="flex" sx={styles.programBtnContainer}>
          <Link underline="none" href="/#program">
            <Button variant="kitPrimary" size="large">
              Узнать больше о программах
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Section>
  );
}
